<template>
	<v-app dark style="background: transparent" class="pa-0">
		<v-main style="background: transparent;" v-if="themeLoaded" class="pa-0">
			<v-container fluid>
				<router-view
					:empresa="empresa"
					:nombreEmpresa="nombreEmpresa"
					:telefono="telefono"
					:email="email"
					class="pa-0"
				/>
			</v-container>
		</v-main>

		<v-footer style="background: transparent" class="d-flex align-end">
			<h4>900 908 885</h4>
			<a href="https://alumbraenergia.es" class="ma-2 ml-auto d-flex justify-end">
				<v-img max-width="120" max-height="120" contain src="/imagenes/logo_alumbraenergia.png"></v-img>
			</a>
		</v-footer>

		<v-snackbar v-model="snack" timeout="4000" color="primary" type="error">
			{{
				msg
			}}
		</v-snackbar>
	</v-app>
</template>

<script>

export default {
	name: 'App',
	data: () => {
		return {
			msg: null,
			snack: false,

			empresa: '',
			nombreEmpresa: '',
			telefono: '',
			email: '',

			themeLoaded: false
		}
	},
	metaInfo() {
		return {
			title: `ALTA WEB | ${'alumbraenergia'.toUpperCase()}`,
			link: [
				{
					rel: "icon",
					href: `./img/fav_alumbraenergia.png`,
				},
			],
		};
	},
	async beforeCreate() {
		let dominio =
			// window.location.host.replaceAll(/www./g, "").split(".")[1] ||
			"alumbraenergia";
		let { data: themes } = await axios({ url: "/themes.json" });
		let aux = {};
		Object.values(themes.themes).forEach(
			(t) => (aux[t.name] = t.light.primary)
		);
		if (this.development) this.themes = Object.values(themes.themes);
		let theme = themes.themes[dominio];
		this.theme = theme.name;
		Object.keys(theme.light).forEach((key) => {
			this.$vuetify.theme.themes.light[key] = theme.light[key];
		});
		this.$vuetify.theme.themes.name = theme.name;

		this.empresa = theme.name;
		this.nombreEmpresa = theme.slug;
		this.telefono = theme.footer.telefono;
		this.email = theme.footer.email;

		this.themeLoaded = true;
	},
	methods: {
		setTheme(theme) {
			return new Promise(async (resolve) => {
				if (!this.development) return;
				this.themeLoaded = false;
				if (typeof theme != "object")
					theme = this.themes.find(({ name }) => name == theme);
				this.theme = theme.name;
				Object.keys(theme.light).forEach((key) => {
					this.$vuetify.theme.themes.light[key] = theme.light[key];
				});
				this.$vuetify.theme.themes.name = theme.name;
				this.themeLoaded = true;
				resolve();
			});
		},
	},
	async mounted() {
		this.$root.$on("snack", (msg) => {
			this.snack = true;
			this.msg = msg;
		});
	}
};
</script>

<style scoped>
@font-face {
	font-family: "silkaregular";
	src: url("/fuente/silka-regular-webfont.eot");
	src: url("/fuente/silka-regular-webfont.eot?#iefix")
			format("embedded-opentype"),
		url("/fuente/silka-regular-webfont.woff2") format("woff2"),
		url("/fuente/silka-regular-webfont.woff") format("woff"),
		url("/fuente/silka-regular-webfont.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "silkaregular_italic";
	src: url("/fuente/silka-regularitalic-webfont.eot");
	src: url("/fuente/silka-regularitalic-webfont.eot?#iefix")
			format("embedded-opentype"),
		url("/fuente/silka-regularitalic-webfont.woff2") format("woff2"),
		url("/fuente/silka-regularitalic-webfont.woff") format("woff"),
		url("/fuente/silka-regularitalic-webfont.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "silka_bold";
	src: url("/fuente/Silka_Bold.otf");

	font-weight: bold;
	font-style: normal;
}

* {
	font-family: "silkaregular";
}

>>> .rounded-pill
	.v-progress-linear.v-progress-linear--absolute.v-progress-linear--visible {
	width: calc(100% - 50px);
	left: 50%;
	transform: translateX(-50%);
	/* border-radius: 9999px; */
}
</style>